import { gsap, SplitText, ScrollTrigger, DrawSVGPlugin } from "gsap/all";
export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);
  // window.gsap = gsap;
  // window.draggable = Draggable;
  gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin);
  let mm = gsap.matchMedia();
  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers:
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? true
        : false,
    // markers: false,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //--------------------------------//
  // Parallax effect
  //--------------------------------//
  const height = window.innerHeight;
  document
    .querySelectorAll(".overview-posts-fancy .alloy-sf-item")
    .forEach((container) => {
      let notPlayed = true;
      const q = gsap.utils.selector(container);

      const timeline = gsap.timeline({
        defaults: {
          ease: "none",
        },
        scrollTrigger: {
          trigger: container, // What element triggers the scroll
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "top center", // Can be top, center, bottom
          end: "bottom top", // Can be top, center, bottom
          onEnter: () => {
            if (notPlayed) {
              notPlayed = false;
              gsap.from(q(".animate-in"), {
                yPercent: 70,
                autoAlpha: 0,
              });
            }
          },
        },
      });
      // timeline.set(q('.animate-in'), { opacity: 0 })
      // timeline.to(q('.animate-in'), {
      //   yPercent: -50,
      //   opacity: 1,
      //   duration: 2,
      // });
      timeline.to(q(".animate-parallax"), {
        y: () => {
          const amount = -(height / 4) * gsap.utils.random([0.9, 0.6, 0.3]);
          return amount;
        },
      });

      if (q(".image").length > 0) {
        timeline.from(
          q(".image"),
          {
            y: () => (height / 6) * gsap.utils.random([0.6, 0.3]),
          },
          "<",
        );
      }
    });
  // END Parallax effect  --------------//
  //--------------------------------//
  // 📌 Pin container
  //--------------------------------//
  // add a media query. When it matches, the associated function will run
  mm.add("(min-width: 992px)", () => {
    document
      .querySelectorAll(".alloy-pin-container")
      .forEach(function (container) {
        if (container.querySelector(".sidebar")) {
          const item = container.querySelector(".sidebar");
          const height = item.querySelector(".size").offsetHeight;
          const timeline = gsap.timeline({
            scrollTrigger: {
              trigger: container, // What element triggers the scroll
              scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
              start: "top 70px", // Can be top, center, bottom
              end: `bottom ${height}px`, // Can be top, center, bottom
              pin: item.querySelector(".size"),
              pinSpacer: false,
            },
          });
        }
      });
  });
  // END 📌 Pin container --------------//

  //--------------------------------//
  // Jaarverslag
  //--------------------------------//
  document
    .querySelectorAll(".single-themas.jaarverslag")
    .forEach((container) => {
      // Jaar
      if (container.querySelector(".jaar")) {
        const splitText = new SplitText(container.querySelector(".jaar"), {
          type: "chars",
        });
        gsap.from(splitText.chars, {
          duration: 1,
          yPercent: 100,
          stagger: 0.05,
        });
      }

      // graphs based on ScrollTrigger
      document.querySelectorAll(".animate-graphs").forEach((item) => {
        const q = gsap.utils.selector(item);
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=20%",
            end: "bottom center",
            toggleActions: "play none none reverse",
          },
        });
        tl.from(q("svg >*"), {
          scale: 0,
          transformOrigin: "center center",
          stagger: {
            amount: 1,
          },
        });
      });

      document.querySelectorAll(".animate-images").forEach((item) => {
        const q = gsap.utils.selector(item);
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=20%",
            end: "bottom center",
            toggleActions: "play none none reverse",
          },
        });
        tl.from(q("img"), {
          scale: 0,
          transformOrigin: "center center",
          stagger: {
            amount: 1,
          },
        });
      });

      document.querySelectorAll("#partner-illustratie").forEach((item) => {
        const q = gsap.utils.selector(item);
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=20%",
            end: "bottom center",
            toggleActions: "play none none reverse",
            // scrub: true,
          },
        });
        tl.from(q("#lines line"), {
          drawSVG: 0,
          duration: 0.3,
          transformOrigin: "center center",
          ease: "none",
          stagger: {
            amount: 0.5,
          },
        });
        tl.from(
          q("#rest > g"),
          {
            scale: 0,
            duration: 0.3,
            transformOrigin: "center center",
            ease: "none",
            stagger: {
              amount: 0.5,
            },
          },
          0.3,
        );
      });

      document
        .querySelectorAll(".personeelsomvang-animation")
        .forEach((item) => {
          const q = gsap.utils.selector(item);
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: item,
              start: "top bottom-=20%",
              end: "bottom center",
              toggleActions: "play none none reverse",
            },
          });
          tl.from(q(".scale-x rect"), {
            scaleX: 0,
            transformOrigin: "center left",
            stagger: {
              amount: 1,
            },
          });
          tl.from(q("svg > g:not(.scale-x)"), {
            scale: 0,
            transformOrigin: "center left",
            stagger: {
              amount: 1,
            },
          });
        });
    });
  // END Jaarverslag --------------//
};
